/* global clearInterval, console, setInterval */

const jsonschema = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "result": {
      "type": "array",
      "items": {
        "type": "array",
        "items": {
          "oneOf": [
            { "type": "integer" },
            { "type": "string" },
            { "type": "number" },
            { "type": "boolean" }
          ]
        }
      }
    }
  },
  "required": ["result"]
}

export const toFullModel = (selectedModel) => {
  // same as toFullModel of Models/index.tsx
  switch (selectedModel) {
    case "GPT-3.5":
      return "gpt-3.5-turbo-0125";
    case "GPT-4":
      return "gpt-4o-2024-05-13";
    default:
      return "Unknown model"
  }
}

function identifyArrayStructure(value) {
  if (value === null) { // {to check}: is it possible that value is `undefined`?
    return "null";
  }

  // Check if it's not an array, meaning it's a single value.
  // 42 or "abc"
  if (!Array.isArray(value)) {
    return "Single Value";
  }

  // Check for "Single Value Array" - a 2D array with one element.
  // A2
  if (Array.isArray(value) && value.length === 1 && Array.isArray(value[0]) && value[0].length === 1) {
    return "Single Value Array";
  }

  // Check if it's a 1-dimensional vertical list.
  // [["item 1"], ["item 2"], ["item 3"]]
  if (Array.isArray(value) && value.every(item => Array.isArray(item) && item.length === 1)) {
    return "1-Dimensional Vertical List";
  }
  
  // Check if it's a 1-dimensional horizontal list.
  // [['apple', 'banana', 'cherry']]
  if (Array.isArray(value) && value.length === 1 && Array.isArray(value[0]) && value[0].every(item => !Array.isArray(item))) {
    return "1-Dimensional Horizontal List";
  }

  // Check if it's a 2-dimensional array
  // [[1, 2], [3, 4]]
  if (Array.isArray(value) && value.every(item => Array.isArray(item) && item.some(subItem => !Array.isArray(subItem)))) {
    return "2-Dimensional Array";
  }
  
  // If none of the above, it's considered as others
  // [1, [2], 3]
  return "Others";
}

function containsAnythingThatIsNotEmptyString(input) {
  if (input === null) { // {to check}: is it possible that input is `undefined`?
    return false;
  }

  const structure = identifyArrayStructure(input);
  switch (structure) {
    case "Single Value":
      return input !== "";
    default:
      return input.some(row => row.some(element => element !== ''));
  }
}

async function updateTotalRemainingIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL) {
  try {
    const baseUrl = `${REACT_APP_FUNFUN_URL}/httpOnly/stripe/subscriptions`
    const queryParams = new URLSearchParams({ language: "en", caller: REACT_APP_VERSIONOFFICIAL }).toString();
    const url = `${baseUrl}?${queryParams}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    });

    const data = await res.json();
    const totalRemainingIQ = data?.chatBasicUsageLimit?.totalRemainingIQ;
    if (totalRemainingIQ !== undefined) {
        await OfficeRuntime.storage.setItem("shared-totalRemainingIQ", JSON.stringify(totalRemainingIQ));
        console.log("OfficeRuntime.storage.setItem totalRemainingIQ by updateTotalRemainingIQ before the end of a function", totalRemainingIQ)
    }
    return data;
  } catch (error) {
    console.error('Error in updateTotalRemainingIQ', error);
    throw error;
  }
}

async function isLoggedIn(token, REACT_APP_FUNFUN_URL) {
  try {
    const y = await fetch(`${REACT_APP_FUNFUN_URL}/httpOnly/isLoggedIn`, {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: null
    })
    if (y.status === 401)
      return false
    else
      return true
  } catch (error) {
    console.error('Error in isLoggedIn', error);
    throw error;
  }
}

async function getText(address) {
  // https://github.com/chengtie/10Studio/issues/398
  const context = new Excel.RequestContext();

  const range = context.workbook.worksheets.getActiveWorksheet().getRange(address);
  range.load(["values", "text", "numberFormat"]);
  await context.sync();
  console.log("range.values", range.values);
  console.log("range.text", range.text);
  console.log("range.numberFormat", range.numberFormat);
  return range.text;
}

// {to better}: similar functions exist in AI2Builtin
function extractSheetNameAndRange(address) {
  const lastExclamationIndex = address.lastIndexOf("!");
  const sheetName = address.slice(0, lastExclamationIndex);
  const cellAddress = address.slice(lastExclamationIndex + 1);
  return { sheetName, cellAddress };
}
function removeQuotes(str) {
  return str.replace(/^'(.*)'$/, '$1');
}

async function getWhatToUpload(address) {
  // https://github.com/chengtie/10Studio/issues/398
  const context = new Excel.RequestContext();

  const { sheetName, cellAddress } = extractSheetNameAndRange(address);
  const range = context.workbook.worksheets.getItem(removeQuotes(sheetName)).getRange(cellAddress);
  range.load(["values", "text", "numberFormat"]);
  await context.sync();
  console.log("range.values", range.values);
  console.log("range.text", range.text);
  console.log("range.numberFormat", range.numberFormat);

  let newArray = [];
  for (let i = 0; i < range.values.length; i++) {
    newArray[i] = [];
    for (let j = 0; j < range.values[i].length; j++) {
      if (range.numberFormat[i][j] === "General") {
        newArray[i][j] = range.values[i][j]
      } else if (range.numberFormat[i][j] === "@") {
        newArray[i][j] = range.text[i][j]
      } else {
        newArray[i][j] = range.text[i][j]
      }
      // // Convert value to string and compare with text
      // if (String(range.values[i][j]) === range.text[i][j]) {
      //   newArray[i][j] = range.values[i][j];  // Take from values if equal
      // } else {
      //   // 8.00 as Number: 8 as values, "8.00" as text, "0.00" as numberFormat
      //   console.log("value", range.values[i][j]);
      //   console.log("String(value)", String(range.values[i][j]));
      //   console.log("text", range.text[i][j]);
      //   console.log("numberFormat", range.numberFormat[i][j]);
      //   newArray[i][j] = range.text[i][j];  // Take from texts if not equal
      // }
    }
  }
  return newArray;
}

async function getStableSettings() {
  try {
    // get the stable settings
    let settings = JSON.parse(await OfficeRuntime.storage.getItem("shared-settings"));
    let retryCount = 0;
    console.log("functions, settings, retryCount: " + retryCount, settings);
    while (!(settings && settings.email && settings.email !== "undefined") && retryCount < 3) {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait a bit before retrying
        settings = JSON.parse(await OfficeRuntime.storage.getItem("shared-settings"));
        retryCount++;
        console.log("functions, settings, retryCount: " + retryCount, settings);
    }
    if (!(settings && settings.email && settings.email !== "undefined")) {
      throw new Error("settings not stable or ready");
    }
    return settings;
  } catch (error) {
    console.error('Error in getStableSettings', error);
    throw error;
  }
}

async function possiblySetFunctionEngineChosen(settings, token, REACT_APP_FUNFUN_URL) {
  try {
    // set functionEngineChosen (if it's undefined) of settings for the database of 'user' and 'aiResult'
    if (settings.functionEngineChosen === undefined) {
      settings = { ...settings, functionEngineChosen: settings.functionEngineDefault };
      const x = await fetch(`${REACT_APP_FUNFUN_URL}/httpOnly/users-settings-functionEngineChosen`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ functionEngineChosen: settings.functionEngineDefault })
      });
      if (!x.ok) throw new Error(`HTTP error! status: ${x.status}`);
    }

    return settings;
  } catch (error) {
    console.error('Error in possiblySetFunctionEngineChosen', error);
    throw error;
  }
}

async function prepare() {
  try {
    // Step: get basic variables
    const token = await OfficeRuntime.storage.getItem("shared-token");
    if (!token) // in Excel for Windows, I'm not sure running the first custom function opens a page behind the screen. If not, token is null.
      return { earlyResult: [["Please open the taskpane and sign in first to run AI functions."]] };

    console.log("OfficeRuntime.storage.getItem('shared-token')", token)
    const { REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL } = JSON.parse(await OfficeRuntime.storage.getItem("shared-env"));

    // Step: check if the user is logged in
    const z = await isLoggedIn(token, REACT_APP_FUNFUN_URL);
    if (!z)
      return { earlyResult: [["Please open the taskpane and sign in first to run AI functions."]] };

    // Step: check if totalRemainingIQ is less than or equal to 0
    const totalRemainingIQ = await OfficeRuntime.storage.getItem("shared-totalRemainingIQ");
    console.log("OfficeRuntime.storage.getItem('shared-totalRemainingIQ')", totalRemainingIQ)
    console.log("Get totalRemainingIQ from storage in the beginning of a function", totalRemainingIQ);
    if (totalRemainingIQ !== null && totalRemainingIQ <= 0)
      return { earlyResult: [["You need to purchase more IQ points to run AI functions. Click on the top right of the tool and select 'Upgrade to Pro Plan'."]] };

    // Step: check if functions are enabled or paused
    let settings = await getStableSettings();
    if (!settings.functionsEnabled)
      return { earlyResult: [["AI functions are currently paused. Enable them in the 'Spreadsheet AI Functions' page in the taskpane."]] };

    // Step: possibly set functionEngineChosen
    console.log("functions, selectedModel", settings.selectedModel);
    settings = await possiblySetFunctionEngineChosen(settings, token, REACT_APP_FUNFUN_URL)

    // Step: numberDecimalSeparator
    const numberDecimalSeparator = await OfficeRuntime.storage.getItem("shared-numberDecimalSeparator")
    const text_numberDecimalSeparator = numberDecimalSeparator === "." ? "point" : numberDecimalSeparator === "," ? "comma" : "comma";

    return { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult: null };
  } catch (error) {
    console.error('Error in prepare', error);
    throw error;
  }
}

async function sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation) {
  try {
    const response = await fetch(`${REACT_APP_FUNFUN_URL}/httpOnly/ask/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: JSON.stringify({
        messages: messages,
        settings: settings, model: toFullModel(settings.selectedModel), json: "YES",
        caller: "custom-functions", invocationInfo: {_functionName: invocation._functionName, parameterAddresses: invocation.parameterAddresses } })
    })
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    await updateTotalRemainingIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL);

    const res = await response.text();
    const result = JSON.parse(res).result;
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    throw error;
  }
}

/**
 * Makes up a prompt, submits it to the AI, and outputs the result in a single value or an array. The most general, powerful, and versatile AI function.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiask
 * @param {string[][][]} prompt_part Anything (a cell, a range, a single value, or an array).
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} A 2-dimensional array.
 * @requiresParameterAddresses
 **/
async function ASK(prompt_part, invocation) {
  // =SAI.ASK("give me a random number")
  // =SAI.ASK(A1:B1) where A1 is "what is the biggest city of" and B1 is "United States"
  // =SAI.ASK("find the 3 largest values from"; A1:B1; "and join them with a comma")
  // =SAI.ASK("find the common values of"; A1:A5; "and"; B1:C5; "then remove any duplicates and return a vertical list")
  // =SAI.ASK("find the values that are in"; A1:B10; "but not in"; C1:D10; "then remove any duplicates and return a horizontal list")
  // =SAI.ASK("what are the 5 biggest cities of the US?")

  // =ASK("stack the two tables"; A1:B3; "and"; C1:D3; "into one table; and return a table")
  // =ASK("left join the two tables"; A1:B3; "and"; C1:D3; "on"; "A1=C1"; "and return the table")
  // =ASK("remove the people of"; A1:A4; "from the table"; B1:C10; "and return the table")
  // =ASK("only keep gmails from the table"; A1:B10)
  // https://github.com/MatrixFun/AutoXL?tab=readme-ov-file#full-list-of-functions
  console.log("invocation", invocation);
  console.log("invocation.parameterAddresses", invocation.parameterAddresses);

  const flatten = (arr) => {
    if (arr === null) return "";
    const s = identifyArrayStructure(arr);
    const r = s === "Single Value" ? arr : arr.map(row => row.join('\t')).join('\n');
    return "\n" + r
  }

  // if a prompt is either undefined or single value or single value array, and there is no \n in its single value
  const isSimple = (p) => {
    if (p === null) return true;
    const s = identifyArrayStructure(p);
    // console.log("p", p);
    if (s === "Single Value") return !String(p).includes('\n');
    if (s === "Single Value Array") return !String(p[0][0]).includes('\n');
    return false
  }

  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters: check if all prompt parts make containsAnythingThatIsNotEmptyString false
    const allEmpty = prompt_part.every(part => !containsAnythingThatIsNotEmptyString(part));
    if (allEmpty) return [["Please provide a non-empty prompt."]];

    // Step: adjust what to upload
    for (var i = 0; i < invocation.parameterAddresses.length; i++) {
      if (invocation.parameterAddresses[i] !== null) {
        const x = await getWhatToUpload(invocation.parameterAddresses[i]);
        prompt_part[i] = x;
      }
    }
    console.log("new prompt_part", prompt_part);

    // Step: prepare the prompt and messages
    let messages, prompt;

    const areSimple = prompt_part.every(part => isSimple(part));
    if (areSimple) {
      prompt = prompt_part.filter(containsAnythingThatIsNotEmptyString).join(' ');
      messages = [
        { role: "system", content:
          "I have extracted a prompt from an Excel spreadsheet," + " " +
          `where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + " " +
          "Please respond with either a flattened message, a list of single values, or a 2-dimensional array of single values," + " " +
          "so that it can be converted into a final 2-dimensional array and written to a cell or a range in Excel." + " " +
          "Please provide a valid JSON output containing this final 2-dimensional array." + " " +
          "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]" },
        { role: "user", content: prompt }
      ]
    } else {
      prompt = prompt_part.reduce((acc, part) => acc + flatten(part), '');
      prompt = prompt.replace(/^\n+/, ""); // remove leading newlines

      messages = [
        // experience: maybe try give AI the different parts of the prompt and let AI combine them?
        { role: "system", content: "I have extracted a prompt from an Excel spreadsheet," + " " +
          `where the decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + " " +
          "The tables are formatted as text, with columns separated by tabs ('\t') and rows by new lines ('\n')." + " " +
          "Please respond with either a flattened message, a list of single values, or a 2-dimensional array of single values," + " " +
          "so that it can be converted into a final 2-dimensional array and written to a cell or a range in Excel." + " " +
          "Please provide a valid JSON output containing this final 2-dimensional array." + " " +
          "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]" },
        { role: "user", content: prompt }
      ]
    }
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("error", error);
    console.error("error.toString()", error.toString());
    return [[error.toString()]];
  }
}

/**
 * Submits an array of prompts to the AI and returns an array of corresponding responses, preserving the same dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saipromptarray
 * @param {string[][]} prompts An array of prompts.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of corresponding responses.
 * @requiresParameterAddresses
 **/
async function PROMPTARRAY(prompts, invocation) {
  // naming: AI.ARRAY, AI_ARRAY, AI.ASKARRAY（不好）; SAI.ARRAY, SAI_ARRAY, SAI.ASKARRAY which follows RANDARRAY (and maybe MAKEARRAY)
    // 20240305 我觉得还是叫askarray好，告诉我们是对array中的每个元素进行操作
    // 20240313 https://chat.openai.com/c/a10995eb-bc45-4281-ac1d-1db9ee0e1b15
  // 是不能够这么写的，因为程序不知道该怎么编了：=SAI.PROMPTARRAY("what's the capital of"; A1:A5) where A1:A5 is a list of countries
    // 但是可以这么写：=SAI.PROMPTARRAY("what's the capital of " & A1:A5) where A1:A5 is a list of countries
  // {to do}: should be able to send all the prompts in one request, and get all the completions in one response.
  // 我：每个prompt单独处理
  // test:
    // =SAI.PROMPTARRAY("add 10 to " & {1.2;3.4})
    // =SAI.PROMPTARRAY({"give me 2 random values"."give me 1 random value";"give me 1 random value"."give me 1 random value"})
    // =SAI.PROMPTARRAY({"give me 2 random values"."give me 1 random value"})
    // =SAI.PROMPTARRAY({"give me 2 random values";"give me 1 random value"})
      // note that it can return [[3, 8], 4], the current flattenArray cannot align the dimension, thus the formula returns #VALUE!

  function flattenArray(array) {
    return array.map(element => {
      // Check if the element is an array and process accordingly
      if (Array.isArray(element)) {
        // If the element is a 3-dimensional array, convert inner arrays to string
        return element.map(subElement =>
          Array.isArray(subElement) ? subElement.join(', ') : subElement
        );
      } else {
        // For non-array elements, just return the element
        return element;
      }
    });
  }
  // Testing the function with examples
  console.log("flattenArray([[[42, 17], 8], [23, 5]])", flattenArray([[[42, 17], 8], [23, 5]])); // => [['42, 17', 8], [23, 5]]
  console.log("flattenArray([[[42, 17]], [true]])", flattenArray([[[42, 17]], [true]])); // => [['42, 17'], [true]]

  try {
    let array = prompts;
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters: check if the parameter is empty
    if (!containsAnythingThatIsNotEmptyString(array)) return [["Please provide a non-empty prompt."]];

    // Step: adjust what to upload
    array = invocation.parameterAddresses[0] === null ? array : await getWhatToUpload(invocation.parameterAddresses[0]);
    console.log("array", array);

    // Step: prepare the prompt and messages
    const structure = identifyArrayStructure(array);
    console.log("structure", structure);
    const array_adjusted = array === "Single Value" ? [[array]] : array;

    const messages = [
      // works for [["give me one random number"], ["give me one random number"]]; does NOT work for [["give me two random numbers"], ["give me one random number"]]
      // { role: "system", content: "I have an array of contents. Please complete each item individually, and provide the entire output as valid JSON, representing a 2-dimensional array while preserving the original dimensions. Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]" },
      // Works for [["give me two random numbers"], ["give me one random number"]]. But the format is always string.
      // { role: "system", content: `I have an array of contents. Please process each item individually and get a string response for each item, then use these results to form one valid JSON output, representing a 2-dimensional array and preserving the original dimension. Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[str]]`},
      // Still does not work for [["give me two random numbers"], ["give me one random number"]]. We did not manage to prevent "give me two random numbers" from returning an array.
      // { role: "system", content: `I have a 2-dimensional array containing various elements. My goal is to process each element individually to generate a response for each, where the type of response can be an integer, string, number, or boolean; but it cannot be an array. After processing, I need to compile these responses into a new 2-dimensional array that mirrors the structure of the original array. The structure and data types of the final array must adhere to a specific JSON schema, which requires the outer structure to be an object with a key named 'result'. This 'result' key should map to a 2-dimensional array where each inner array corresponds to the processed results of the original array, maintaining the original array's dimensions and order.\nHere is the JSON schema that defines the required structure and types for the output:\n${JSON.stringify(jsonschema, null, 2)}` },
      // { role: "system", content: "I have an array of contents. Please complete each item individually, and provide the entire output as valid JSON, representing an array while preserving the original dimensions. Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]" },
      { role: "system", content:
        `I have an 'array' which is 2-dimensional array of prompts, extracted from an Excel spreadsheet, where the decimal separator is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preseves the same dimensions as 'array' and holds an empty value in each cell." + "\n" +
        "Then you go through the prompts in 'array'. For each prompt," + " " +
        "1) you generate a response and obtain a single value result." + " " +
        "2) you assign the result for this prompt to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]"
      },
      { role: "user", content: JSON.stringify(array_adjusted) }
    ]

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    const result_adjusted = flattenArray(result);
    return result_adjusted;
  } catch (error) {
    console.error("error", error);
    console.error("error.toString()", error.toString());
    return [[error.toString()]];
  }
}

/**
 * Submits a prompt to the AI and outputs the results in a table.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saigeneratetable
 * @param {string[][]} prompt Anything (a cell, a range, a single value, or an array).
 * @param {string[][]} [head] Headers and possibly some data samples.
 * @param {string[][]} [inputs] Partial rows to complete with.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} A table.
 * @requiresParameterAddresses
 **/
async function GENERATETABLE(prompt, head, inputs, invocation) {
  // gptforwork: GPT_TABLE. "Like GPT, but outputs the results in a table. Very practical when the output is a table."
  // naming: AI.TABLE, AI_TABLE, AI.ASKTABLE（不好）；SAI.TABLE, SAI_TABLE, SAI.ASKTABLE（不好）
  // gptforwork：整合一片prompt；我：整合一片prompt
  // however, i need to do:
    // 一种办法是：signature改为：TABLE(head, inputs, prompt_part1, prompt_part2, prompt_part3)，不好，让用户首先2个不知道写什么。
    // 一种办法是：
      // 首先得有一个特别general的：
        // =ASK("stack the two tables"; A1:B3; "and"; C1:D3; "into one table; and return a table")
        // =ASK("left join the two tables"; A1:B3; "and"; C1:D3; "on"; "A1=C1"; "and return the table")
        // =ASK("remove the people of"; A1:A4; "from the table"; B1:C10; "and return the table")
        // =ASK("remove non-working emails from the table"; A1:B10; "and return the table")
        // 如何能保证不出现5 biggest countries结果不好那种情况？？？
          // 说出来是要往excel中写。Please anwser it in either one flatten message or a 2-dimensional array that can be written to Excel. Comments are unnecessary.
            // Please provide an anwser that can be written to a cell or a range in Excel (one flatten message, one list, or one table).
          // 用pythonschema: List[List[str]]好像对类型不好。
          // 用function calling？writeASingleValueToExcel, writeAListToExcel (writeAListOfSingleValuesToExcel), writeATableToExcel (writeATableOfSingleValuesToExcel)
          // 到底要不要提供list这种可能，还是他明白如何给一个list
      // 然后可以自定义返回的形状：（真的必须吗？？？）
        // 之前用名字定义返回类型的可以变为：cell, list, table或者single, list, table或者value, list, table或者ASKVALUE, ASKLIST, ASKTABLE或者GETVALUE, GETLIST, GETTABLE
          // https://chat.openai.com/c/8de52e18-a350-496a-a5db-8abf47928189
          // 而TABLE(prompt_part1, prompt_part2, prompt_part3)是指更general的返回一个table
        // 那么可不可以只用一个函数代替其他（ask, list, table），然后返回什么形状写在prompt里（取决于效果怎么样），
          // 或者作为一个parameter呢（那这个parameter只能放最前面）？？？
          // rows columns放在最前面的话，有时候我们不好说table的具体大小
      // 得有一个从array到array的（之前的ASKARRAY）
        // 现在可以保留ASKARRAY，也可以用ASK来实现，也可以用CELLARRAY/SINGLEARRAY来实现，或者叫PROMPTARRAY因为只能有一个prompt parameter
        // 但是叫什么呢？？？
      // 原来的TABLE可以叫GENERATETABLE（更好些，因为其他function也没缩写）或者GENTABLE。之后还有关于formula的（data可以省略"data"）
        // 但是这个函数真的还有必要吗？？？
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters: check if the prompt is empty
    if (!containsAnythingThatIsNotEmptyString(prompt)) return [["Please provide a non-empty prompt."]];

    // Step: adjust what to upload
    prompt = invocation.parameterAddresses[0] === null ? prompt : await getWhatToUpload(invocation.parameterAddresses[0]);
    if (head) head = invocation.parameterAddresses[1] === null ? head : await getWhatToUpload(invocation.parameterAddresses[1]);
    if (inputs) inputs = invocation.parameterAddresses[2] === null ? inputs : await getWhatToUpload(invocation.parameterAddresses[2]);
    
    // Step: prepare the prompt and messages
    const structure = identifyArrayStructure(prompt);
    console.log("structure", structure);

    let systemContent;
    if (head && inputs) {
      systemContent = `I have a json data with 3 properties: 'prompt', 'head', and 'inputs'. They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text. Please generate a result table satisfying the following conditions: 1) the 'head' rows, the 'inputs' columns, and the result table together should form an entire table to answer 'prompt'; 2) the result table should complete the 'inputs' columns; 3) the result table and 'inputs' together should follow the 'head' rows; 4) the result table should not contain contents in the 'inputs' columns or the 'head' rows. Please provide a valid JSON output containing this result table. Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]`
    } else if (head) {
      systemContent = `I have a json data with 2 properties: 'prompt' and 'head'. They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text. Please generate a result table satisfying the following conditions: 1) the 'head' rows and the result table together should form an entire table to answer 'prompt'; 2) the result table should not contain contents in the 'head' rows. Please provide a valid JSON output containing this result table. Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]`
    } else if (inputs) {
      systemContent = `I have a json data with 2 properties: 'prompt' and 'inputs'. They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text. Please generate a result table satisfying the following conditions: 1) the 'inputs' columns and the result table together should form an entire table to answer 'prompt'; 2) the result table should complete the 'inputs' columns; 3) the result table and 'inputs' together should follow the 'head' rows; 4) the result table should not contain contents in the 'inputs' columns. Please provide a valid JSON output containing this result table. Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]`
    } else {
      systemContent = `I have a json data with 1 property: 'prompt'. They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text. Please answer it by generating a table. The output should be a valid JSON output, conforming to the following Pydantic specification:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]`
    }
    
    let promptAdjusted;
    switch (structure) {
      case "Single Value": case "Single Value Array":
        promptAdjusted = structure === "Single Value" ? prompt : prompt[0][0]
        break;
      case "1-Dimensional Vertical List":
        promptAdjusted = prompt.map(element => element[0])
        break;
      case "1-Dimensional Horizontal List":
        promptAdjusted = prompt[0];
        break;
      case "2-Dimensional Array":
        promptAdjusted = prompt;
        // messages = [
        //   { role: "system", content: "I have an array of contents as a prompt. Please answer it by generating a table (without the prompt array). The output should be a valid JSON output, conforming to the following Pydantic specification:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]" },
        //   { role: "user", content: JSON.stringify(prompt) }
        // ];
        // messages = [
        //   { role: "system", content: "I have a prompt. Please answer it by generating a table. The output should be a valid JSON output, conforming to the following Pydantic specification:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]" },
        //   { role: "user", content: JSON.stringify(prompt.map(row => row.map(item => item.replace(/[\t\n]/g, ' ')).join('\t')).join('\n')) }
        // ];
        break;
      case "Others":
        return "impossible"
    }

    const userContent = { prompt: promptAdjusted };
    if (head) userContent['head'] = head;
    if (inputs) userContent['inputs'] = inputs;
    const messages = [
      { role: "system", content: systemContent },
      { role: "user", content: JSON.stringify(userContent) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Completes a table by learning examples. The function for the Flash Fill feature of Excel.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saifill
 * @param {string[][]} examples Examples to learn from.
 * @param {string[][]} [inputs] Partial rows to complete with.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} A table.
 * @requiresParameterAddresses
 **/
async function FILL(examples, inputs, invocation) {
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(examples)) return [["Please provide non-empty examples."]];

    // Step: adjust what to upload
    examples = invocation.parameterAddresses[0] === null ? examples : await getWhatToUpload(invocation.parameterAddresses[0]);
    if (inputs) inputs = invocation.parameterAddresses[1] === null ? inputs : await getWhatToUpload(invocation.parameterAddresses[1]);

    let messages;
    if (!inputs) {
      const x = { examples: examples };
      messages = [
        { role: "system", content: "I have a json data with a properties: 'examples'." + " " +
          `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
          "Please learn the pattern of the data in 'examples', take them as examples, and generate a result table." + " " +
          "Please provide a valid JSON output containing this result table." + " " +
          "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]"
        },
        { role: "user", content: JSON.stringify(x) }
      ]
    } else {
      const x = { examples: examples, inputs: inputs };
      messages = [
        { role: "system", content: "I have a json data with two properties: 'examples' and 'inputs'." + " " +
          `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
          "Please learn the pattern of the data in 'examples' and generate a result table for 'inputs'" + " " +
          "such that the 'inputs' and the result table together follows the pattern of 'examples'." + " " +
          "Make sure that the result table does not contain contents in the 'inputs' columns." + "\n" +
          "Please provide a valid JSON output containing this result table." + " " +
          "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]"
        },
        { role: "user", content: JSON.stringify(x) }
      ]
    }
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Splits a text semantically, such as into sections, paragraphs, sentences, words, or based on punctuation.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saisplit
 * @param {string[][]} text A text (a cell, a range, a single value, or an array).
 * @param {string[][]} split_by How to split the text (e.g., "words", "sentences", "paragraphs").
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} A vertical list of splits.
 * @requiresParameterAddresses
 **/
async function SPLIT(text, split_by, invocation) {
  // gptforwork: given "Here is Tie.", "There is Jone.", "Jimmy is over there", "Lucy is nearby". in a 2x2 range,
    // =GPT_SPLIT(E33:F34;"paragraph") returns "Here is Tie.	There is jone." and "Jimmy is over there.	Lucy is nearby."
    // it means gptforwork links a row with tabs and links a column with newlines
  // gptforwork：整合一片text；我：整合一片text
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(text)) return [["Please provide a non-empty text to split."]];
    if (!containsAnythingThatIsNotEmptyString(split_by)) return [["Please provide a non-empty 'split_by' parameter."]];
    const structure_split_by = identifyArrayStructure(split_by);
    if (structure_split_by !== "Single Value" && structure_split_by !== "Single Value Array") return [["Please provide one single value for the 'split_by' parameter."]];

    // Step: adjust what to upload
    text = invocation.parameterAddresses[0] === null ? text : await getText(invocation.parameterAddresses[0]);
    split_by = invocation.parameterAddresses[1] === null ? split_by : await getText(invocation.parameterAddresses[1]);

    // Step: prepare the prompt and messages
    const split_by_adjusted = structure_split_by === "Single Value Array" ? split_by[0][0] : split_by;

    const structure_text = identifyArrayStructure(text);
    const textMerged = structure_text === "Single Value" ? text : text.map(row => row.join('\t')).join('\n');
    console.log("textMerged", textMerged)

    const x = { text: textMerged, split_by: split_by_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'text' and 'split_by'." + "\n" +
        "Please split the 'text' by the 'split_by' and obtain a list of the split results." + " " +
        "The output should be a valid JSON output containing that list of the split results," + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[str]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    const result_adjusted = JSON.parse(res).result.map(element => [element]);
    console.log("result_adjusted", result_adjusted);
    return result_adjusted;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Extracts data (like email addresses or company names) from a text.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiextract
 * @param {string[][]} text A text (a cell, a range, a single value, or an array).
 * @param {string[][]} to_extract What to extract from the text.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} Extractions.
 * @requiresParameterAddresses
 **/
async function EXTRACT(text, to_extract, invocation) {
  // gptforwork: GPT_EXTRACT. "Extracts data (like email addresses or company names) from a text. Outputs as comma-separated values."
  // excel online, where ; is the argument separator
    // =GPT_EXTRACT("China UK France"; "country") 返回 "ChinaUKFrance"，而不是像它说的那样返回 "China, UK, France"。一个错误！！！
    // =GPT_EXTRACT({"Here is France"; "There is UK"}; "country") 返回"France, UK" in a cell rather than a range
    // =GPT_EXTRACT({"jone.smith@example.com 555-123-456"; "sj@goolge.com 444-178-213"}; {"number"; "email"}) 返回 {"555-123-456, 444-178-213"; "jone.smith@example.com, sj@goolge.com"}
    // =GPT_EXTRACT({"jone.smith@example.com 555-123-456"; "sj@goolge.com 444-178-213"}; {"number". "email"}) 返回 {"555-123-456, 444-178-213". "jone.smith@example.com, sj@goolge.com"}
      // 所以是按照to_extract的dimension走
  // naming: AI.EXTRACT, AI_EXTRACT；SAI.EXTRACT, SAI_EXTRACT
  // gptforwork：整合一片text；我：整合一片text
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(text)) return [["Please provide a non-empty text to extract from."]];
    if (!containsAnythingThatIsNotEmptyString(to_extract)) return [["Please provide a non-empty 'to_extract' parameter."]];

    // Step: adjust what to upload
    text = invocation.parameterAddresses[0] === null ? text : await getText(invocation.parameterAddresses[0]);
    to_extract = invocation.parameterAddresses[1] === null ? to_extract : await getText(invocation.parameterAddresses[1]);

    // Step: prepare the prompt and messages
    const structureText = identifyArrayStructure(text);
    console.log("structure", structureText);
    const textMerged = structureText === "Single Value" ? text : text.map(row => row.join(' ')).join(' ');

    const structure_to_extract = identifyArrayStructure(to_extract);
    const to_extract_adjusted = structure_to_extract === "Single Value" ? [[to_extract]] : to_extract;

    const x = { text: textMerged, to_extract: to_extract_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'text' and 'to_extract' which is a 2-dimensional array." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'to_extract' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'to_extract'. For each item," + " " +
        "1) you extract the corresponding data from 'text', join them with ', ' if there are multiple." + " " +
        "2) you assign the extraction(s) for this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[str]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Processes extraction on an array of texts and returns an array of corresponding extractions, preserving the dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiextractarray
 * @param {string[][]} texts An array of texts.
 * @param {string} to_extract What to extract from the texts.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of extractions.
 * @requiresParameterAddresses
 **/
async function EXTRACTARRAY(texts, to_extract, invocation) {
  // naming: AI.EXTRACTARRAY, AI_EXTRACTARRAY; SAI.EXTRACTARRAY, SAI_EXTRACTARRAY
  // 我：每个text单独处理
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(texts)) return [["Please provide non-empty texts to extract from."]];
    if (!containsAnythingThatIsNotEmptyString(to_extract)) return [["Please provide a non-empty 'to_extract' parameter."]];

    // Step: adjust what to upload
    texts = invocation.parameterAddresses[0] === null ? texts : await getText(invocation.parameterAddresses[0]);
    to_extract = invocation.parameterAddresses[1] === null ? to_extract : await getText(invocation.parameterAddresses[1]);

    // Step: prepare the prompt and messages
    const structure_texts = identifyArrayStructure(texts);
    const texts_adjusted = structure_texts === "Single Value" ? [[texts]] : texts;

    const structure_to_extract = identifyArrayStructure(to_extract);
    const to_extract_adjusted = structure_to_extract === "Single Value Array" ? to_extract[0][0] : to_extract;

    const x = { texts: texts_adjusted, to_extract: to_extract_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'texts' which is a 2-dimensional array and 'to_extract'." + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'texts' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'texts'. For each item," + " " +
        "1) you extract 'to_extract' from the item, join them with ', ' if there are multiple." + " " +
        "2) you assign the extraction(s) from this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[str]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Applies a task to a value, e.g., fixing the grammar and spelling of a text.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiedit
 * @param {string[][]} value A value (a cell, a range, a single value, or an array).
 * @param {string[][]} task What to do with the value.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns A single value result.
 * @requiresParameterAddresses
 **/
async function EDIT(value, task, invocation) {
  // gptforwork: GPT_EDIT. "Applies the given task to the given text. The default task is to fix grammar and spelling."
    // =GPT_EDIT({"Here is Tie.". "There is Jone."}; "remove the ending point.") returns "Here is Tim. There is John." 说明不太好使
  // naming: AI.EDIT, AI_EDIT; SAI.EDIT, SAI_EDIT
  // gptforwork：整合一片text；我：整合一片text
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(task)) return [["Please provide a non-empty task to undertake."]];
    const structure_task = identifyArrayStructure(task);
    if (structure_task !== "Single Value" && structure_task !== "Single Value Array") return [["Please provide one single value for the task."]];

    // Step: adjust what to upload
    value = invocation.parameterAddresses[0] === null ? value : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const task_adjusted = structure_task === "Single Value Array" ? task[0][0] : task;
    
    const structure_value = identifyArrayStructure(value);
    const valueMerged = structure_value === "Single Value" ? value : value.map(row => row.join('\t')).join('\n');
    console.log("valueMerged", valueMerged)

    const x = { value: valueMerged, task: task_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'value' and 'task'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "Please undertake the 'task' to edit the 'value', and obtain a single value result." + " " +
        "Please return a valid JSON output containing this result." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: Any"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Applies a task to an array of values and returns an array of corresponding results, preserving the dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saieditarray
 * @param {string[][]} values An array of values.
 * @param {string[][]} task What to do with the values.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of results.
 * @requiresParameterAddresses
 **/
async function EDITARRAY(values, task, invocation) {
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(task)) return [["Please provide a non-empty task to undertake."]];
    const structure_task = identifyArrayStructure(task);
    if (structure_task !== "Single Value" && structure_task !== "Single Value Array") return [["Please provide one single value for the task."]];

    // Step: adjust what to upload
    values = invocation.parameterAddresses[0] === null ? values : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const structure_values = identifyArrayStructure(values);
    const values_adjusted = structure_values === "Single Value" ? [[values]] : values;

    const task_adjusted = structure_task === "Single Value Array" ? task[0][0] : task;

    const x = { values: values_adjusted, task: task_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'values' which is a 2-dimensional array and a string 'task'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'values' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'values'. For each item," + " " +
        "1) you undertake the 'task' to edit the item, and obtain a single value result." + " " +
        "2) you assign the single value result for this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Formats texts, dates, currencies, addresses, names, etc.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiformat
 * @param {string[][]} value A value (a cell, a range, a single value, or an array).
 * @param {string[][]} target_format A target format like "iso", "title case", "uppercase".
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns A single value result.
 * @requiresParameterAddresses
 **/
async function FORMAT(value, target_format, invocation) {
  // gptforwork: GPT_FORMAT. "Format dates, currencies, addresses, names, etc. Fix capitalization. And so much more."
    // `=GPT_FORMAT({"14 febrary 71";"15 febrary 71";"16 febrary 71"};"iso")` returns "1971-02-14\n1971-02-15\n1971-02-16" or "1971-02-141971-02-151971-02-16"
  // naming: AI.FORMAT, AI_FORMAT; SAI.FORMAT, SAI_FORMAT
  // gptforwork：整合一片text；我：整合一片text
  // similar structure to EDIT
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(target_format)) return [["Please provide a non-empty target format."]];
    const structure_target_format = identifyArrayStructure(target_format);
    if (structure_target_format !== "Single Value" && structure_target_format !== "Single Value Array") return [["Please provide one single value for the 'target_format' parameter."]];

    // Step: adjust what to upload
    value = invocation.parameterAddresses[0] === null ? value : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const target_format_adjusted = structure_target_format === "Single Value Array" ? target_format[0][0] : target_format;
    
    const structure_value = identifyArrayStructure(value);
    const valueMerged = structure_value === "Single Value" ? value : value.map(row => row.join('\t')).join('\n');
    console.log("valueMerged", valueMerged)

    const x = { value: valueMerged, target_format: target_format_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'value' and 'target_format'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        // experience: "Please apply the format 'target_format' to the 'input', and obtain a single value result. Please return this result." would return "abc" or "2023-02-24" with double quotes.
        "Please apply the format 'target_format' to the 'value', and obtain a single value result." + " " +
        "Please return a valid JSON output containing this result." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: Any"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Formats an array of values and returns an array of corresponding results, preserving the dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiformatarray
 * @param {string[][]} values An array of values.
 * @param {string[][]} target_format A target format like "iso", "title case", "uppercase".
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of results.
 * @requiresParameterAddresses
 **/
async function FORMATARRAY(values, target_format, invocation) {
  // 我：每个text单独处理
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(target_format)) return [["Please provide a non-empty target format."]];
    const structure_target_format = identifyArrayStructure(target_format);
    if (structure_target_format !== "Single Value" && structure_target_format !== "Single Value Array") return [["Please provide one single value for the 'target_format' parameter."]];

    // Step: adjust what to upload
    values = invocation.parameterAddresses[0] === null ? values : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const structure_values = identifyArrayStructure(values);
    const values_adjusted = structure_values === "Single Value" ? [[values]] : values;

    const target_format_adjusted = structure_target_format === "Single Value Array" ? target_format[0][0] : target_format;

    const x = { values: values_adjusted, target_format: target_format_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'values' which is a 2-dimensional array and 'target_format'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'values' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'values'. For each item," + " " +
        "1) you apply the format 'target_format' to the item, and obtain a single value result." + " " +
        "2) you assign the result for this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[Any]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Classifies a text into a category.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiclassify
 * @param {string[][]} text A text (a cell, a range, a single value, or an array).
 * @param {string[][]} categories A single string value of comma-separated category names, or an array of category names.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns The most relevant category that the text belongs to.
 * @requiresParameterAddresses
 **/
async function CLASSIFY(text, categories, invocation) {
  // gptforwork:
    // =GPT_CLASSIFY("banana"; "fruit, vegetable") returns "fruit"
    // =GPT_CLASSIFY({"banana"; "apple"; "carrot"}; "fruit, vegetable") returns "fruit"
    // =GPT_CLASSIFY("banana"; {"vegetable";"fruit"}) returns "fruit"
    // =GPT_CLASSIFY("banana"; {"vegetable"."fruit"}) returns "fruit"
      // so gptforworks merges value, and merges categories, and then does the classification
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(texts)) return [["Please provide non-empty texts to classify."]];
    if (!containsAnythingThatIsNotEmptyString(categories)) return [["Please provide one or several categories."]];

    // Step: adjust what to upload
    text = invocation.parameterAddresses[0] === null ? text : await getText(invocation.parameterAddresses[0]);

    // Step: preapre the prompt and messages
    const structure_categories = identifyArrayStructure(categories);
    const categories_adjusted = structure_categories === "Single Value" ? categories : categories.map(row => row.join(' ')).join(' ')
    
    if (!containsAnythingThatIsNotEmptyString(text)) return [["Please provide a non-empty value to classify."]];
    const structure_text = identifyArrayStructure(text);
    const text_adjusted = structure_text === "Single Value" ? text : text.map(row => row.join(' ')).join(' ');

    const x = { text: text_adjusted, categories: categories_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'text' and 'categories'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "Please classify the 'text' into one of the 'categories'." + " " +
        "Please return a valid JSON output containing the result category." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: str"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Classifies an array of texts and returns an array of corresponding categories, preserving the dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saiclassifyarray
 * @param {string[][]} texts An array of texts.
 * @param {string[][]} categories A single string value of comma-separated category names, or an array of category names.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of categories.
 * @requiresParameterAddresses
 **/
async function CLASSIFYARRAY(texts, categories, invocation) {
  // 我：每个value单独处理
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(texts)) return [["Please provide non-empty texts to classify."]];
    if (!containsAnythingThatIsNotEmptyString(categories)) return [["Please provide one or several categories."]];

    // Step: adjust what to upload
    texts = invocation.parameterAddresses[0] === null ? texts : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const structure_texts = identifyArrayStructure(texts);
    const texts_adjusted = structure_texts === "Single Value" ? [[texts]] : texts;

    const structure_categories = identifyArrayStructure(categories);
    const categories_adjusted = structure_categories === "Single Value" ? categories : categories.map(row => row.join(' ')).join(' ')

    const x = { texts: texts_adjusted, categories: categories_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'texts' which is a 2-dimensional array and 'categories'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'texts' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'texts'. For each item," + " " +
        "1) you apply the item into one of the 'categories'." + " " +
        "2) you assign the category for this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[str]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Applies user-defined tags to a text.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saitag
 * @param {string[][]} text A text (a cell, a range, a single value, or an array).
 * @param {string[][]} tags A single string value of comma-separated tag names, or an array of tag names.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns A single string of relevant tags.
 * @requiresParameterAddresses
 **/
async function TAG(text, tags, invocation) {
  // gptforwork:
  // similar structure to CLASSIFY
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(text)) return [["Please provide a non-empty text to tag."]];
    if (!containsAnythingThatIsNotEmptyString(tags)) return [["Please provide one or several tags."]];

    // Step: adjust what to upload
    text = invocation.parameterAddresses[0] === null ? text : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const structure_tags = identifyArrayStructure(tags);
    const tags_adjusted = structure_tags === "Single Value" ? tags : tags.map(row => row.join(' ')).join(' ')
    
    const structure_text = identifyArrayStructure(text);
    const text_adjusted = structure_text === "Single Value" ? text : text.map(row => row.join(' ')).join(' ');

    const x = { text: text_adjusted, tags: tags_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'text' and 'tags'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "Please identify one or several tags from 'tags' that are pertinent to the 'value'. Join the pertinent tags with ', ' if there are multiple." + " " +
        "Please return a valid JSON output containing the result string." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: str"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Tags an array of texts and returns an array of corresponding tags, preserving the dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saitagarray
 * @param {string[][]} texts An array of texts.
 * @param {string[][]} tags A single string value of comma-separated tag names, or an array of tag names.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of corresponding relevant tags.
 * @requiresParameterAddresses
 **/
async function TAGARRAY(texts, tags, invocation) {
  // 我：每个value单独处理
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(texts)) return [["Please provide non-empty texts to tag."]];
    if (!containsAnythingThatIsNotEmptyString(tags)) return [["Please provide one or several tags."]];

    // Step: adjust what to upload
    texts = invocation.parameterAddresses[0] === null ? texts : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const structure_texts = identifyArrayStructure(texts);
    const texts_adjusted = structure_texts === "Single Value" ? [[texts]] : texts;

    const structure_tags = identifyArrayStructure(tags);
    const tags_adjusted = structure_tags === "Single Value" ? tags : tags.map(row => row.join(' ')).join(' ')

    const x = { texts: texts_adjusted, tags: tags_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'texts' which is a 2-dimensional array and 'tags'." + "  " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'texts' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'texts'. For each item," + " " +
        "1) you identify one or several tags from 'tags' that are pertinent to the item. Join the pertinent tags with ', ' if there are multiple." + " " +
        "2) you assign this string of tags for this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[str]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Summarizes a text according to a format.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saisummarize
 * @param {string[][]} text A text (a cell, a range, a single value, or an array).
 * @param {string[][]} format A format like "less than 20 words", "3 sentences".
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns A single value result.
 * @requiresParameterAddresses
 **/
async function SUMMARIZE(text, format, invocation) {
  // gptforwork:
  // naming: AI.FORMAT, AI_FORMAT; SAI.FORMAT, SAI_FORMAT
  // gptforwork：整合一片text；我：整合一片text
  // similar structure to EDIT
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(text)) return [["Please provide a non-empty text to summarize."]];
    if (!containsAnythingThatIsNotEmptyString(format)) return [["Please provide a non-empty format in which the text will be summarized."]];
    const structure_format = identifyArrayStructure(format);
    if (structure_format !== "Single Value" && structure_format !== "Single Value Array") return [["Please provide one single value for the 'format' parameter."]];

    // Step: prepare the prompt and messages
    const format_adjusted = structure_format === "Single Value Array" ? format[0][0] : format;
    
    const structure_text = identifyArrayStructure(text);
    const textMerged = structure_text === "Single Value" ? text : text.map(row => row.join('\t')).join('\n');
    console.log("textMerged", textMerged)

    const x = { text: textMerged, format: format_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'text' and 'format'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "Please summarize the 'text' in the 'format', and obtain a string result." + " " +
        "Please return a valid JSON output containing this string result." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: str"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    // console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}

/**
 * Summarizes an array of texts and returns an array of summaries, preserving the dimensions.
 * @customfunction
 * @helpurl https://www.10studio.tech/docs/spreadsheet-ai-functions#saisummarizearray
 * @param {string[][]} texts An array of texts.
 * @param {string[][]} format A format like "less than 20 words", "3 sentences".
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @returns {string[][]} An array of summaries.
 * @requiresParameterAddresses
 **/
async function SUMMARIZEARRAY(texts, format, invocation) {
  try {
    // Step: prepare
    const { token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, settings, text_numberDecimalSeparator, earlyResult } = await prepare();
    if (earlyResult !== null) return earlyResult;

    // Step: check parameters
    if (!containsAnythingThatIsNotEmptyString(texts)) return [["Please provide non-empty texts to summarize."]];
    if (!containsAnythingThatIsNotEmptyString(format)) return [["Please provide a non-empty format in which the texts will be summarized."]];
    const structure_format = identifyArrayStructure(format);
    if (structure_format !== "Single Value" && structure_format !== "Single Value Array") return [["Please provide one single value for the 'format' parameter."]];

    // Step: adjust what to upload
    texts = invocation.parameterAddresses[0] === null ? texts : await getText(invocation.parameterAddresses[0]);

    // Step: prepare the prompt and messages
    const structure_texts = identifyArrayStructure(texts);
    const texts_adjusted = structure_texts === "Single Value" ? [[texts]] : texts;

    const format_adjusted = structure_format === "Single Value Array" ? format[0][0] : format;

    const x = { texts: texts_adjusted, format: format_adjusted };

    const messages = [
      { role: "system", content: "I have a json data with two properties: 'texts' which is a 2-dimensional array and 'format'." + " " +
        `They were extracted from an Excel spreadsheet where the number decimal separator of Excel is a ${text_numberDecimalSeparator} and numbers can be formatted as text.` + "\n" +
        "You initiate a 2-dimensional result array that preserves the same dimensions as 'texts' and holds an empty string in each cell." + "\n" +
        "Then you go through the items in 'texts'. For each item," + " " +
        "1) you summarize the item in the 'format' and obtain a string result." + " " +
        "2) you assign the result for this item to the corresponding cell in the result array." + "\n" +
        "Please return a valid JSON output containing the final result array." + " " +
        "Use the following Pydantic specification for the output:\\nclass MyResultModel(BaseModel):\\n    result: List[List[str]]"
      },
      { role: "user", content: JSON.stringify(x) }
    ]
    console.log("messages", messages);

    const result = await sendRequestAndUpdateIQ(token, REACT_APP_FUNFUN_URL, REACT_APP_VERSIONOFFICIAL, messages, settings, invocation);
    console.log("result", result);
    return result;
  } catch (error) {
    console.error("Fetching error: ", error);
    return [[error.toString()]];
  }
}
CustomFunctions.associate("ASK", ASK);
CustomFunctions.associate("PROMPTARRAY", PROMPTARRAY);
CustomFunctions.associate("GENERATETABLE", GENERATETABLE);
CustomFunctions.associate("FILL", FILL);
CustomFunctions.associate("SPLIT", SPLIT);
CustomFunctions.associate("EXTRACT", EXTRACT);
CustomFunctions.associate("EXTRACTARRAY", EXTRACTARRAY);
CustomFunctions.associate("EDIT", EDIT);
CustomFunctions.associate("EDITARRAY", EDITARRAY);
CustomFunctions.associate("FORMAT", FORMAT);
CustomFunctions.associate("FORMATARRAY", FORMATARRAY);
CustomFunctions.associate("CLASSIFY", CLASSIFY);
CustomFunctions.associate("CLASSIFYARRAY", CLASSIFYARRAY);
CustomFunctions.associate("TAG", TAG);
CustomFunctions.associate("TAGARRAY", TAGARRAY);
CustomFunctions.associate("SUMMARIZE", SUMMARIZE);
CustomFunctions.associate("SUMMARIZEARRAY", SUMMARIZEARRAY);